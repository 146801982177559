// import registerRootComponent from 'expo/build/launch/registerRootComponent';

// import App from '../../App';

// registerRootComponent(App);
const appName = 'Cert'

import {registerRootComponent} from 'expo';

import App from '../../App';
import {AppRegistry} from 'react-native';
// import {name as appName} from '../../app.json';

AppRegistry.registerComponent(appName, () => App);
registerRootComponent(App);